// React Components and Other Libraries
import React, { useState, useEffect } from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import { convertGiveBeeIntToUSDDecimal } from "../../helpers/PointsAndMoneyConversions";

// UI CSS
import coinIcon from "../../assets/images/coin-icon.svg";
import xIcon from "../../assets/images/X-icon-dark.svg";

const HowToEarnPointsModal: React.FC<any> = (props:any) => {

  const {
    howToEarnModalOpen,
    setHowToEarnModal,
  } = props;

  useEffect(() => {
    setModal();
  }, []);

  function setModal() {
    if (howToEarnModalOpen) {
      const element = document.getElementById("rewardOverlay");
      if (element !== null) {
        element.style.display = "block";
      }
    }
  }

  return (
    <Row className="rewardContentModal">
      <Col>
        <Row>
          <Col className="text-right">
            <button
              className="button-icon mr-3"
              onClick={() => setHowToEarnModal(false) }
            >
              <img className="regularIcon ml-3 mt-3" alt="exit" src={ xIcon } />
            </button>
          </Col>
        </Row>
        <Row>
          <Col>
            <h4 className="mt-2">How to Earn Points</h4>
          </Col>
        </Row>
        <Row>
          <Col className="text-left">
            <h5>GiveBee (most points)</h5>
            <ul>
              <li>
                Download GiveBee on the App Store
              </li>
              <li>
                Snap a photo of your order!
              </li>
              <li>
                Upload your photo to GiveBee and post it to your profile!
              </li>
              <li>
                Go to the notifications tab and post to your Instagram Story and tag @tapas.barcelona!
              </li>
              <li>
                Cross your fingers and wait for the winner list on November 6th!
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col>
            <h5>Normal:</h5>
            <ul>
              <li>
                Snap a photo of your order!
              </li>
              <li>
                Post it to your Facebook or Instagram Story and tag us! (@tapas.barcelona on Instagram)
              </li>
              <li>
                DM us a screenshot of your post if your account is private
              </li>
              <li>
                Normal posts earn 5 points
              </li>
              <li>
                Cross your fingers and wait for the winner list on November 6th!
              </li>
            </ul>
          </Col>
        </Row>
      </Col>
    </Row>
  );

}

export default HowToEarnPointsModal;