import React, { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Row, Col, Nav } from "react-bootstrap";
import Burger from "./Nav/Burger";

import logo from "../../assets/images/GiveBee_201.png";
import homeAsset from "../../assets/images/home-page-asset.png";
import appStoreIcon from "../../assets/images/download-on-app-store.svg";
import foodieFindsAsset from "../../assets/images/post-finds-asset.png";
import socialSharePhone from "../../assets/images/socialshare-iphone.png";
import earnRewardsAsset from "../../assets/images/earn-rewards-asset.png";

import "./HomePage.css";

interface HomePageProps extends RouteComponentProps {}

const HomePage: React.FC<HomePageProps> = ({ history }) => {
  
  const [locationQuery, setLocationQuery] = useState("");

  function triggerSearch() {
    history.push({
      pathname: "/community-feed/",
      search: `?location=${locationQuery}`
    });
  }

  return (
    <>
      <Nav className="navbar navbar-expand-lg navbar-light sticky-top">
        <img
          src={logo}
          alt="GiveBee - Get the people buzzin'!"
          className="logo navbar-brand"
          onClick={() => {
            history.push("/")
          }}
        />
        <Burger history={ history } />
      </Nav>
      <Row className="row-lightYellow row-top">
        <Col xs={12} md={6} className="column-regular getSponsoredCol my-auto">
          <h1>Support & share your favorite places.</h1>
          <h5>Automatically enter giveaways and earn rewards by supporting your favorite restaurants online and offline.</h5>
          <a href="https://apps.apple.com/us/app/givebee/id1394721571">
          <img id="appStoreIcon" className="mb-3" alt="AppStore" src={appStoreIcon} />
          </a>
        </Col>
        <Col xs={12} md={4} className="centerContentX my-auto">
          <img id="appScreenImg" alt="" src={ homeAsset } />
        </Col>
      </Row>
      <Row className="centerContentX row-about">
        <Col className="theCallCol">
          <h2>Your favorite businesses need<br/>their favorite advertiser:</h2>
          <h2 id="you" className="mt-3 mb-3">You</h2>
          <h5 className="mx-2">Your content and sharing power are the new word-of-mouth<br/>and local retaurants need all of us to create a buzz.</h5>
          <h5 className="mx-2">Together, we can create an Internet where people are the producers,<br/>not the product.</h5>
        </Col>
        <hr className="hr-homePage"/>
      </Row>
      <Row className="centerContentX">
        <Col xs={12} md={4} className="mb-3 px-3">
          <img className="productImg mb-3" alt="" src={ foodieFindsAsset } />
          <h4 className="font-weight-demibold mb-3">Post all your foodie finds.</h4>
        </Col>
        <Col xs={12} md={4} className="mb-3 px-3">
          <img className="productImg mb-3" alt="" src={ socialSharePhone } />
          <h4 className="font-weight-demibold mb-3">Share anywhere. Share everywhere.</h4>
        </Col>
        <Col xs={12} md={4} className="px-3">
          <img className="productImg mb-3" alt="" src={ earnRewardsAsset } />
          <h4 className="font-weight-demibold">Earn rewards for shopping and sharing.</h4>
        </Col>
      </Row>
      <br />
      <Row className="px-3">
        <Col>
          <p className="ml-3">&copy; 2020 GiveBee, LLC</p>
        </Col>
        <Col className="centerContentY text-right">
          <p className="centerContentY mr-3"><a href="/legal/privacy" className="a-reg">Privacy</a></p>
          <p className="centerContentY mr-3"><a href="/legal/terms" className="a-reg">Terms</a></p>
          <p className="centerContentY mr-3"><a href="/support" className="a-reg">Support</a></p>
        </Col>
      </Row>
    </>
  );
}

export default HomePage;