import React, { useState } from "react";
import { functions } from "../../../firebase";
import { Row, Col, InputGroup, Form, FormControl, Dropdown, SplitButton, ButtonGroup, Spinner } from "react-bootstrap";

const BusinessesInquiryForm: React.FC<any> = (props:any) => {

  const [fullName, setFullName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [subimittingForm, setSubmittingForm] = useState(false);
  const [successfulSubmission, setSuccessfulSubmission] = useState(false);
  const [submissionError, setSubmissionError] = useState("");


  const validatePhoneNumber = (value:any) => {

    console.log("Sending in value:", value);
    const previousValue = phoneNumber;

    // return nothing if no value
    if (!value) { console.log("First fail"); return value; }

    // only allows 0-9 inputs
    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length; 

    if (!previousValue || value.length > previousValue.length || value.length === previousValue.length - 1) {
      
      // returns: "x", "xx", "xxx"
      if (cvLength < 4) { console.log("Second fail"); setPhoneNumber(currentValue); }

      // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
      if (cvLength < 7) { console.log("Third fail"); setPhoneNumber(`(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`); }

      // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
      const finalValue = `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
      setPhoneNumber(finalValue);

    } else {
      console.log("Big fail");
    }
  }


  const disableSubmission = () => {
    return (fullName.length < 2 || emailAddress.length < 2 || businessName.length < 2 || phoneNumber.length !== 14);
  }

  const handleSubmit = (event:any) => {

    event.preventDefault();
    setSubmissionError("");
    setSubmittingForm(true);

    const businessWaitlistObj = {
      businessName: businessName,
      email: emailAddress,
      fullName: fullName,
      phoneNumber: phoneNumber,
    };

    var addBusinessToWaitlist = functions.httpsCallable("createWaitlistedBusiness");
    addBusinessToWaitlist(businessWaitlistObj)
      .then((result:any) => {
        console.log("Success:", result);
        setSubmittingForm(false);
        setSuccessfulSubmission(true);
        setFullName("");
        setBusinessName("");
        setEmailAddress("");
        setPhoneNumber("");
      })
      .catch((err:any) => {
        const code = err.code;
        const message = err.message;
        const details = err.details;
        setSubmittingForm(false);
        setSubmissionError(err.message);
        console.error("Error", code, message, details);
      });
  };

  return (
    <>
    <Form
      className="mt-3"
      onSubmit={handleSubmit} >
      <InputGroup>
        <FormControl
          className="inputField"
          type="text"
          value={fullName}
          placeholder="Full name"
          required={true}
          onChange={(event:React.ChangeEvent<HTMLTextAreaElement>) => { setFullName(event.target.value) }}
        />
      </InputGroup>
      <InputGroup>
        <FormControl
          className="inputField"
          type="email"
          value={emailAddress}
          placeholder="Work email address"
          required={true}
          onChange={(event:React.ChangeEvent<HTMLTextAreaElement>) => { setEmailAddress(event.target.value) }}
        />
      </InputGroup>
      <InputGroup>
        <FormControl
          className="inputField"
          type="text"
          value={businessName}
          placeholder="Business name"
          required={true}
          onChange={(event:React.ChangeEvent<HTMLTextAreaElement>) => { setBusinessName(event.target.value) }}
        />
      </InputGroup>
      <InputGroup>
        <InputGroup.Prepend>
          <span className="input-group-text inputField" id="basic-addon1">+1</span>
        </InputGroup.Prepend>
        <FormControl
          className="inputField"
          type="phone"
          value={phoneNumber}
          placeholder="Phone number"
          required={true}
          onChange={(event:React.ChangeEvent<HTMLTextAreaElement>) => { validatePhoneNumber(event.target.value) }}
        />
      </InputGroup>
      {/* <InputGroup>
        <FormControl
          className="inputField"
          type="text"
          value={reasonForInquiry || ""}
          placeholder="What can we help with?"
        />
        <InputGroup.Append>
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic" className="dropdownToggle inputField" />
            <Dropdown.Menu>
              <Dropdown.Item eventKey="1" className="font-weight-demibold">Retaining loyal customers</Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item eventKey="2" className="font-weight-demibold">Increasing social shares</Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item eventKey="3" className="font-weight-demibold">Getting more photos to repost</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </InputGroup.Append>
      </InputGroup> */}
      <Row>
        <Col xs={12} md={6} className="text-right mt-3">
          <p className="businessesH5 font-weight-demibold">Already have an account?<br /><a href="/business/signin">Sign in here</a></p>
        </Col>
        <Col xs={12} md={6} >
          <Spinner hidden={!subimittingForm} animation="border" />
          <button
            type="submit"
            disabled={ disableSubmission() || subimittingForm }
            className="businessesFormButton"
          >
            Submit
          </button>
        </Col>
      </Row>
      <Row>
        <Col>
          <p hidden={ submissionError === "" }>Error: { submissionError }</p>
        </Col>
      </Row>
    </Form>
    <h5 className="businessesH5" hidden={ !successfulSubmission }>Thank you! &#x1F603; We'll be in touch soon!</h5>
    </>
  )
}

export default BusinessesInquiryForm;