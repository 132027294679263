import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import HomePage from "./components/homePage/HomePage";
import BusinessesPage from "./components/homePage/Businesses/BusinessesPage";
import AppPage from "./components/homePage/AppPage";
import BusinessSignUp from "./components/businessAuth/BusinessSignUp";
import BusinessSignIn from "./components/businessAuth/BusinessSignIn";
import BusinessAccountPage from "./components/businessUser/BusinessAccountPage";
import NoniOSLandingPage from "./components/landingPage/NoniOSLandingPage";
import PhotosFeedPage from "./components/homePage/PhotosFeedPage";
import ContestsPage from "./components/contests/ContestsPage";
import SelectedContestPage from "./components/contests/SelectedContestPage";
import PrivacyPage from "./components/termsAndSupport/PrivacyPage";
import TermsPage from "./components/termsAndSupport/TermsPage";
import SupportPage from "./components/termsAndSupport/SupportPage";

import "./App.css";

const supportsHistory = "pushState" in window.history;

class App extends Component {
  render() {
    return (
      <Router forceRefresh={!supportsHistory}>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/incomingLinks/*" component={HomePage} />
          <Route path="/app" component={AppPage} />
          <Route exact path="/business" component={BusinessesPage} />
          <Route path="/business/signin" component={BusinessSignIn} />
          <Route path="/business/signup" component={BusinessSignUp} />
          <Route path="/business/account/*" component={BusinessAccountPage} />
          <Route path="/business/onboarding/*" component={BusinessAccountPage} />

          <Route path="/contests" component={ContestsPage} />
          <Route exact path="/contest-page" component={SelectedContestPage} />
          <Route path="/community-feed" component={PhotosFeedPage} />

          <Route path="/legal/privacy" component={PrivacyPage}/>
          <Route path="/legal/terms" component={TermsPage}/>
          <Route path="/support" component={SupportPage} />
          <Route exact path="/sponsorships" component={NoniOSLandingPage} />
          <Route path="*" component={HomePage} />
        </Switch>
      </Router>
    );
  }
}

export default App;
