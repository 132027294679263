import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";

interface AppPageProps extends RouteComponentProps {}

const AppPage: React.FC<AppPageProps> = () => {
  
  useEffect(() => {
    // Check for the OS and route accordingly
    detectOperatingSystemAndRoute()
  }, []);

  // OS detection from: https://stackoverflow.com/a/39749991
  function getMobileOS() {
    var userAgent = navigator.userAgent || navigator.vendor;

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent)) {
        return "iOS";
    }
    return "unknown";
  }

  function detectOperatingSystemAndRoute() {
    let os = getMobileOS()
    if (os === "iOS") {
      window.location.href = "https://apps.apple.com/us/app/givebee/id1394721571?ls=1"
    } else {
      window.location.href = "https://www.givebee.co/";
    }
  };

  return (
    <div/>
  );
};

export default AppPage;