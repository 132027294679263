import React, { useState, useEffect } from "react";
import { functions } from "../../firebase";
import { RouteComponentProps } from "react-router-dom";
import { Row, Col, Nav, NavItem } from "react-bootstrap";
import { LazyImage } from "../../helpers/LazyImage";
import { PhotoModal } from "./PhotoModal";

import logo from "../../assets/images/GiveBee_201.png";
import backup from "../../assets/images/givebee-contact-pic.png"
import magGlass from "../../assets/images/icon-search.png";
import pwrdByGoogle from "../../assets/images/powered-by-google.png";
import uploadIcon from "../../assets/images/icon-upload.png";

import "./HomePage.css";
import "./PhotosFeedPage.css";
import "./PhotoModal.css";

interface PhotosFeedPageProps extends RouteComponentProps { }

const PhotosFeedPage: React.FC<PhotosFeedPageProps> = ({ history, location }) => {

  var Geohash = require('ngeohash');
  var service:any;

  // Photo class
  // Note: For most GiveBee photos, most of these vars are undefined
  // variables like openingHours, phoneNumber, website are only called if
  // a GiveBee photo is clicked and a googlePlaceId is present
  class Photo {
    public address:string;
    public businessName: string;
    public businessId:string;          // present in GiveBee Non-Google photos
    public googlePlaceId:string;       // present in GiveBee Google photos
    public locationId:string;          // present in GiveBee Non-Google photos
    public openingHours:Map<string,any>;
    public phoneNumber:string;
    public url:string;
    public userInfo:any;
    public website:string;

    constructor (data:any) {
      this.address = (data.address ? data.address : '');
      this.businessName = (data.businessName ? data.businessName : '');
      this.businessId = data.businessId;
      this.locationId = data.locationId;
      this.googlePlaceId = data.googlePlaceId;
      this.openingHours = data.openingHours;  // will be undefined for GiveBee photos
      this.phoneNumber = (data.phoneNumber ? data.phoneNumber : '');
      this.url = (data.url ? data.url : '');
      this.userInfo = data.userInfo;  // will be undefined for Google photos
      this.website = (data.website ? data.website : '');
    }
  };

  const [lastTimestamp, setLastTimestamp] = useState(null);
  const [giveBeePhotosArray, setGiveBeePhotosArray] = useState([]);
  const [googlePhotosArray, setGooglePhotosArray] = useState([]);
  const [selectedPhoto, setSelectedPhoto] = useState<Photo | undefined>(undefined);

  var tempGooglePhotosArray:any = [];

  useEffect(() => {
    initialLoadSearch()
  }, []);

  function initialLoadSearch() {
    const params = new URLSearchParams(location.search);
    const queryString = params.get("location")
    // Convert the address query into geohash
    // @ts-ignore
    const geocoder = new google.maps.Geocoder;
    geocoder.geocode({ "address": queryString }, queryPhotosMaster);    
  }

  function queryPhotosMaster(results:any, status:any) {
    if (status === 'OK') {
      const geocodeResp = results[0];
      if (geocodeResp) {
        const lat = geocodeResp.geometry.location.lat();
        const lng = geocodeResp.geometry.location.lng();
        const geohash = Geohash.encode(lat, lng, 10);
        queryGiveBeePhotos(geohash); // Send geohash to query givebee photos
        queryGooglePhotos(lat,lng); // Send lat, lng to query Google photos
      }
    }
  }

  function queryGiveBeePhotos(geohash:string) {
    // Get the prefix of length 4
    const geohashPrefix = geohash.substring(0,4);
    // Send request to the firebase functions
    const queryGiveBeePhotos = functions.httpsCallable("queryNearYouPhotos");
    queryGiveBeePhotos({ "geohashPrefix": geohashPrefix })
      .then((result) => {
        console.log("Success");
        // Get the return and add to the photos array
        const data = result.data;
        const timestamp = data.lastTimestamp;
        const photosDataArray = data.photosData;
        if (timestamp !== undefined && photosDataArray !== undefined) {
          setLastTimestamp(timestamp);
          const giveBeeArr = photosDataArray.map((giveBeePhotoDoc:any) => new Photo(giveBeePhotoDoc.data));
          setGiveBeePhotosArray(giveBeeArr);
        }
      })
      .catch((err) => {
        const code = err.code;
        const message = err.message;
        const details = err.details;
        console.error("Error", code, message, details);
      });    
  }

  function queryGooglePhotos(lat:any, lng:any) {
    // @ts-ignore
    var pyrmont = new google.maps.LatLng(lat, lng);
    // Query locations within 1.5 miles
    var request = {
      location: pyrmont,
      radius: "2415",
      type: ["restaurant"]
    }
    // @ts-ignore
    service = new google.maps.places.PlacesService(document.createElement('div')); // TODO: pass in map if this fails, otherwise, clear map
    service.nearbySearch(request, placesFoundCallBack);
  }

  var numberOfPlaces = 0;

  function placesFoundCallBack(results:any, status:any) {
    // @ts-ignore
    if (status === google.maps.places.PlacesServiceStatus.OK) {
      numberOfPlaces = Math.min(results.length, 8);
      for (var i = 0; i < numberOfPlaces; i++) {
        var place = results[i];
        var placeId = place.place_id;
        // Request place details for each location
        var request = {
          placeId: placeId,
          fields: ["formatted_address", "formatted_phone_number", "name", "photos", "website"]
        };
        // @ts-ignore
        service.getDetails(request, addPlaceDetailsToArray);
      }
    }
  }

  var completedDetailsCalls = 0;

  function addPlaceDetailsToArray(place:any, status:any) {
    // @ts-ignore
    if (status === google.maps.places.PlacesServiceStatus.OK) {
      // Grab the photos array, iterate through each one
      const placePhotosArray = place.photos;
      completedDetailsCalls += 1;
      if (placePhotosArray !== undefined) {
        for (var i = 0; i < Math.min(placePhotosArray.length, 3); i++) {
          const photo = placePhotosArray[i];
          const photoUrl = photo.getUrl({ "maxWidth": 238, "maxHeight": 335});
          // create Photo class object and add it to googlePhotosArr
          const photoData = {
            address: place.formatted_address,
            businessName: place.name,
            phoneNumber: place.formatted_phone_number,
            url: photoUrl,
            website: place.website
          };
          const newPhoto = new Photo(photoData);
          tempGooglePhotosArray.push(newPhoto);
        }
      }
      if (completedDetailsCalls === numberOfPlaces) {
        setGooglePhotosArray(tempGooglePhotosArray);
      }
    }
  }

  function headHome() {
    history.push("/");
  }

  function setPhotoView(photo:any) {
    setSelectedPhoto(photo);
    const element = document.getElementById("overlay");
    if (photo !== undefined && element !== null) {
      element.style.display = "block";
    } else if (element !== null) {
      element.style.display = "none"
    }
  }

  const giveBeeImageElements = giveBeePhotosArray.map((photo:Photo, index:number) => {
    return (
      <div className="giveBeeImageDiv"
        key={`giveBeeImage${index}`}>
        <div onClick={() => setPhotoView(photo)}>
        <LazyImage
          className="giveBeeImage"
          alt="communityPhoto"
          src={photo.url}
        />
        <LazyImage
          className="giveBeeProfilePhoto"
          alt="userProfilePhoto"
          src={ (photo.userInfo.profilePhotoUrl !== "") ? photo.userInfo.profilePhotoUrl : backup }
        />
        </div>
      </div>
    )
  });

  const googleImageElements = googlePhotosArray.map((photo:Photo, index:number) => {
    return (
      <div className="giveBeeImageDiv"
        key={`googleImage${index}`}>
          <div onClick={() => { setPhotoView(photo) }}>
          <LazyImage
            className="googleImage"
            alt="googleImage"
            src={photo.url}
          />
        </div>
      </div>
    )
  });

  return (
    <div>
      <Nav>
        <NavItem>
          <img
            src={logo}
            alt="GiveBee - Get the people buzzin'!"
            className="logoNorm"
            onClick={() => {
              headHome();
            }}
          />
        </NavItem>
        <NavItem className="uploadNavItem">
          <img
            className="uploadLogo"
            src={uploadIcon}
            alt="uploadNav"
            onClick={() => {
              history.push("/download");
            }}
          />
        </NavItem>
      </Nav>
      <div className="backgroundDiv">
        { (selectedPhoto !== undefined) &&
          <div id="overlay" onClick={() => { setPhotoView(undefined) } }>
            <PhotoModal selectedPhotoData={selectedPhoto} setPhotoView={ setPhotoView }/>
          </ div>
        }
        <Row>
          <Col>
            <div/>
            { (giveBeePhotosArray.length > 0) ? giveBeeImageElements : console.log("nada") }
          </Col>
        </Row>
        <Row className="callToActionRow">
          <Col>
            <Row>
              <Col>
                <hr className="hrDashed"/>
              </Col>
            </Row>
            <Row>
              <Col>
                <Row>
                  <Col>
                  { (giveBeePhotosArray.length > 0) ? 
                    <h3 className="font-weight-bold">Don't see your favorite restaurant yet? <img id="magGlass" alt="magnifyingGlass" src={magGlass}/></h3> :
                    <h3 className="font-weight-bold">No photos uploaded by this community yet. <img id="magGlass" alt="magnifyingGlass" src={magGlass}/></h3>}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Row>
                      <Col>
                        <h5>Help businesses in your community be found by uploading your old photos. We'll link the photos to their site!</h5>
                      </Col>
                    </Row>
                    <Row className="justify-content-center">
                      <Col id="uploadContentCol">
                        <h3 className="font-weight-bold" onClick={() => { history.push("/download") }}>
                          <img id="uploadContentImg"
                            alt="uploadContentLogo"
                            src={uploadIcon}
                          />
                          Upload Content
                        </h3>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs={4} md={5}>
                <hr className="hrDashedMed"/>
              </Col>
              <Col xs={4} md={2}>
                <img id="poweredByGoogle" alt="poweredByGoogleLogo" src={pwrdByGoogle}/>
              </Col>
              <Col xs={4} md={5}>
                <hr className="hrDashedMed"/>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <div/>
            { (googlePhotosArray.length > 0) ? googleImageElements : console.log("nada from google yet...") }
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PhotosFeedPage;