import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import { RouteComponentProps } from "react-router-dom";
import { Row, Col, Nav, Spinner } from "react-bootstrap";
import { getCurrentTimestamp } from "../../helpers/TimeAndDateFunctions";

import Burger from "../homePage/Nav/Burger";

import logo from "../../assets/images/GiveBee_201.png";

import "./ContestsPage.css"

interface ContestsPageProps extends RouteComponentProps {}

const ContestsPage: React.FC<ContestsPageProps> = ({ location, history }) => {
  
  // Contest class
  class Contest {
    public contestId:string;
    public description:string;
    public end_at:number;
    public start_at: number;
    public name:string;

    constructor (data:any) {
      this.contestId = data.contestId;
      this.description = data.description;
      this.end_at = data.end_at;
      this.start_at = data.start_at;
      this.name = data.name;
    }
  };

  const [contestsLoading, setContestsLoading] = useState(false);
  const [activeContests, setActiveContests] = useState<Contest[]>([]);

  useEffect(() => {
    loadActiveContests()
  }, []);

  const loadActiveContests = async() => {

    // setContestsLoading(true);

    const now = getCurrentTimestamp();

    const contestsDataArr = await db.collection("contests")
                            .orderBy("end_time")
                            .where("end_time", "<", now)
                            .get();
    const contestsArr = contestsDataArr.docs.map((doc:any) => new Contest(doc.data));
    setActiveContests(contestsArr);

    setContestsLoading(false);
  }

  function triggerContestNav(buttonChoice:string) {
    history.push({
      pathname: "/contest-page",
      search: `?contest=${buttonChoice}`
    });
  }

  const ActiveContestButtons = activeContests.map((contest:Contest) =>
    {
      return (
        contest &&
        <button key={`${contest.contestId}`} className="contestButton"></button>
      );
    }
  );

  return (
    <>
      <Nav className="navbar navbar-expand-lg navbar-light sticky-top">
        <img
          src={logo}
          alt="GiveBee - Get the people buzzin'!"
          className="logo navbar-brand"
          onClick={() => {
            history.push("/")
          }}
        />
        <Burger history={ history } />
      </Nav>
      <Row className="centerContentX">
        <Col hidden={ contestsLoading } className="px-3">
          <h1 className="mt-3">Active Contests</h1>
          <h5>Welcome! Look below for a contest you are participating in to view your standing and other details!</h5>
          <h5>Happy buzzing!</h5>
        </Col>
      </Row>
      <Row className="buttonsRow">
        <Spinner hidden={ !contestsLoading } animation="border" className="mx-auto"/>
        <Col hidden={ contestsLoading }>
          <button className="contestButton" onClick={() => { triggerContestNav("NW5YHLB9fx3bSsv0dNo8") } }>Tapas Barcelona Gift Card Giveaway</button>
        </Col>
      </Row>
    </>
  );
};

export default ContestsPage;