// /business
export const businessesPath = "/business"
export const contestsPath = "/contests"
// /business/account/*
export const businessAccountPath = "/business/account/";
export const businessDashboardPath = `${businessAccountPath}dashboard/`;
export const businessCustomersPath = `${businessAccountPath}customers/`;
export const businessContentPath = `${businessAccountPath}content/`;
export const businessActivityPath = `${businessAccountPath}activity/`;
export const businessManageRewardsPath = `${businessAccountPath}rewards/`;
export const businessEditAccountPath = `${businessAccountPath}edit/`;
export const validBusinessAcountPaths = [
  businessDashboardPath,
  businessCustomersPath,
  businessContentPath,
  businessActivityPath,
  businessManageRewardsPath,
  businessEditAccountPath,
];
// /business/onboarding/*
export const businessOnboardingPath = "/business/onboarding/";
export const businessOnboardDetailsPath = `${businessOnboardingPath}businessDetails/`;
export const businessOnboardRewardsPath =`${businessOnboardingPath}setRewards/`;
export const businessOnboardSubscribePath = `${businessOnboardingPath}activation/`;
export const validBusinessOnboardingPaths = [
  businessOnboardDetailsPath,
  businessOnboardRewardsPath,
  businessOnboardSubscribePath,
];
