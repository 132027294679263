import React from "react";
import styled from "styled-components";

import * as pathNames from "../../../constants/PathNames";

const Ul = styled.ul<{ open:boolean }>`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;

  li {
    color: var(--mainDark) !important;
    font-weight: 600;
    font-size: 1.2rem;
    padding: 18px 10px;
  }

  @media (max-width: 768px) {
    flex-flow: column nowrap;
    background-color: #fff;
    position: fixed;
    transform: ${ (props:any) => (props.open ? 'translateX(0)' : 'translateX(100%)') };
    top: 0;
    right: 0;
    height: 100vh;
    width: 300px;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;

    li {
      color: var(--mainDark) !important;
    }
  }

  
`;

const RightNav: React.FC<any> = (props:any) => {

  const { history, open } = props;

  return (
    <Ul open={ open }>
      <li>
        <a
          href={pathNames.businessesPath}
          onClick={() => history.push(pathNames.businessesPath) }
          className="regular-li"
        >
          Business
        </a>
      </li>
      <li>
        <a
          href={pathNames.contestsPath}
          onClick={() => history.push(pathNames.contestsPath) }
          className="regular-li"
        >
          Contests
        </a>
      </li>
    </Ul>
  )
}

export default RightNav;
