import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";

import "./PhotoModal.css";
import backup from "../../assets/images/givebee-contact-pic.png";
import closeX from "../../assets/images/icon-exit.png";
import pin from "../../assets/images/icon-pin.png";
// import clock from "../../assets/images/icon-clock.png";
import phone from "../../assets/images/icon-phone.png";

// @ts-ignore
export const PhotoModal = ({ selectedPhotoData, setPhotoView }) => {

  const [businessName, setBusinessName] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [openingHours, setOpeningHours] = useState();
  const [website, setWebsite] = useState("");

  useEffect(() => {
    if (selectedPhotoData !== undefined) {
      setModal();
      if (selectedPhotoData.googlePlaceId === undefined 
        && selectedPhotoData.businessId === undefined) {
          setBusinessName(selectedPhotoData.businessName);
          setAddress(selectedPhotoData.address);
          setOpeningHours(selectedPhotoData.openingHours);
          setPhoneNumber(selectedPhotoData.phoneNumber);
          setWebsite(selectedPhotoData.website);
      }
      else if (selectedPhotoData.googlePlaceId !== undefined) {
        prepareGiveBeeGooglePhoto(selectedPhotoData.googlePlaceId);
      }
      else {
        prepareGiveBeePhoto(selectedPhotoData.businessId, selectedPhotoData.locationId);
      }
    }
  });

  function setModal() {
    const element = document.getElementById("overlay"); // div with this id is in PhotosFeedPage.tsx
    if (element !== null) {
      element.style.display = "block";
    }
  }

  // Function triggered on close functions
  function reversePhotoView() {
    selectedPhotoData = undefined; // to hide elements
    setPhotoView() // to set off the "recheck" events
  }

  function prepareGiveBeeGooglePhoto(googlePlaceId:string) {
    // @ts-ignore
    const service = new google.maps.places.PlacesService(document.createElement('div')); 
    var request = {
      placeId: googlePlaceId,
      fields: ['formatted_address', 'formatted_phone_number', 'website']
    };
    // @ts-ignore
    service.getDetails(request, addGooglePlaceDetails);
  }
  
  function addGooglePlaceDetails(place:any, status:any) {
    // @ts-ignore
    if (status === google.maps.places.PlacesServiceStatus.OK) {
      setBusinessName(selectedPhotoData.businessName); 
      setAddress(place.formatted_address);
      setPhoneNumber(place.formatted_phone_number);
      setWebsite(place.website);
    }
  }
  
  function prepareGiveBeePhoto(businessId:string, locationId:string) {  
    setBusinessName(selectedPhotoData.businessName)    
  }

  return (
    <Row>
      <Col>
        <Row>
          <Col>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => reversePhotoView()}>
              <img alt="close" src={closeX} />
            </button>
          </Col>
        </Row>
        <Row>
          <Col className="modalUserInfoCol" xs={4} md={3}>
            <Row>
              <Col>
                {(selectedPhotoData.userInfo !== undefined) &&
                <p className="pModal">
                  Shared by:
                </p>}
              </Col>
            </Row>
            <Row>
              <Col>
                {(selectedPhotoData.userInfo !== undefined &&
                selectedPhotoData.userInfo.profilePhotoUrl !== undefined) &&
                <img className="giveBeeModalProfilePhoto" alt="profilePhoto"
                  src={ (selectedPhotoData.userInfo.profilePhotoUrl !== "") ? selectedPhotoData.userInfo.profilePhotoUrl : backup }
                />}
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                {(selectedPhotoData.userInfo !== undefined) &&
                <p className="pModal">
                  { (selectedPhotoData.userInfo.username !== "") ? selectedPhotoData.userInfo.username : selectedPhotoData.userInfo.firstName }
                </p>}
              </Col>
            </Row>
          </Col>

          <Col xs={6} md={5}>
            <img className="giveBeeImage giveBeeModalImage" alt="mainImg" src={selectedPhotoData.url} />
          </Col>

          <Col xs={12} md={3} className="modalLocationInfoCol">
            <Row>
              <Col>
                <h5 className="h5Modal">
                  {businessName}
                </h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="pModal">
                  { address && <img className="modalIcon" alt="pinIcon" src={pin} /> }
                  { address }
                </p>
              </Col>
            </Row>
            {/* <Row>
              <Col>
                <p className="pModal">
                  <img className="modalIcon" src={clock} />
                  Hours not available
                </p>
              </Col>
            </Row> */}
            <Row>
              <Col>
                <p className="pModal">
                  { phoneNumber && <img className="modalIcon" alt="phoneIcon" src={phone} /> }
                  { phoneNumber }
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                {website &&
                <a className="modalLink" href={website} rel="noopener noreferrer" target=" _blank">
                  { website }
                </a>}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};