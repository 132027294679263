import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import { Table, Row, Col, Nav } from "react-bootstrap";
import Countdown from "react-countdown";

import Burger from "../homePage/Nav/Burger";

import logo from "../../assets/images/GiveBee_201.png";

import "./ContestsPage.css"
import HowToEarnPointsModal from "./HowToEarnPointsModal";
import ContestRewardsModal from "./ContestRewardsModal";

const SelectedContestPage: React.FC<any> = (props:any) => {

  // Participant class
  class Participant {
    public contestId:string;
    public contestPoints:number;
    public names:any;
    public userId:string;

    constructor (data:any) {
      this.contestId = data.contestId;
      this.contestPoints = data.contestPoints;
      this.names = data.names;
      this.userId = data.userId;
    }
  };

  const { history, location } = props

  const [participantsLoading, setParticipantsLoading] = useState(false);
  const [contestParticipants, setContestParticipants] = useState<Participant[]>([]);

  const [howToEarnModalOpen, setHowToEarnModal] = useState(false);
  const [rewardsModalOpen, setRewardsModal] = useState(false);

  useEffect(() => {
    loadContestParticipants()
  }, []);

  async function loadContestParticipants() {

    setParticipantsLoading(true);

    const params = new URLSearchParams(location.search);
    const queryString = params.get("contest");

    const queryArr = await db.collection("contestParticipants")
      .where("contestId", "==", queryString)
      .orderBy("contestPoints", "desc")
      .get();
    
    const participantsArr = queryArr.docs.map((doc:any) => new Participant(doc.data()));
    setContestParticipants(participantsArr);

    setParticipantsLoading(false)
  }

  const participantUsername = (person:any) => {
    return (person.username.length > 1) ? person.username : `${person.name.firstName} ${person.name.lastName.substring(0,1)}.`
  }

  const ParticipantsList = contestParticipants.map((participant:Participant) => 
    <tbody>
      <tr>
        <td>{participantUsername(participant)}</td>
        <td>{`${participant.contestPoints}`}</td>
      </tr>
    </tbody>
  );

  return (
    <>
      <Nav className="navbar navbar-expand-lg navbar-light sticky-top">
        <img
          src={logo}
          alt="GiveBee - Get the people buzzin'!"
          className="logo navbar-brand"
          onClick={() => {
            history.push("/")
          }}
        />
        <Burger history={ history } />
      </Nav>
      <Row className="form mt-3">
        <Col>
          { (howToEarnModalOpen) &&
            <div id="rewardOverlay">
              <HowToEarnPointsModal
                howToEarnModalOpen={ howToEarnModalOpen }
                setHowToEarnModal={ setHowToEarnModal }
              />
            </ div>
          }
          { (rewardsModalOpen) &&
            <div id="rewardOverlay">
              <ContestRewardsModal
                rewardsModalOpen={ rewardsModalOpen }
                setRewardsModal={ setRewardsModal }
              />
            </ div>
          }
          <h1 className="contestTitle mt-3 mx-auto px-3">Tapas Barcelona Gift Card Giveaway</h1>
          <Row className="centerContentX mt-3">
            <Col>
              <Countdown className="countdownTimer" date={ new Date(1605225600 * 1000) } />
            </Col>
          </Row>
          <Row>
            <Col className="creatorLine">
              <p>time zone: CST</p>
              <p>Offered by: <a href="https://www.tapasbarcelona.com/" target="_blank">Tapas Barcelona</a></p>
            </Col>
          </Row>
          <Row className="centerContentX mt-3">
            <Col xs={12} md={6}>
              <button className="button-link detailButton" onClick={() => { setHowToEarnModal(true) } }>How to Earn Points</button>
            </Col>
            <Col xs={12} md={6}>
              <button className="button-link detailButton" onClick={() => { setRewardsModal(true) } }>Rewards</button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table className="form mt-3 mainTable">
            <thead>
              <tr>
                <th>Name</th>
                <th>Points</th>
              </tr>
            </thead>
            { ParticipantsList }
          </Table>
        </Col>
      </Row>
    </>
  );
};

export default SelectedContestPage;