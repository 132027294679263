import React, { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Row, Col, Nav, InputGroup, Form, FormControl } from "react-bootstrap";

import Burger from "../Nav/Burger";
import BusinessesInquiryForm from "./BusinessesInquiryForm";

import "./BusinessesPage.css";

import logo from "../../../assets/images/GiveBee_201.png";
import igActive from "../../../assets/images/ig-active.svg";
import igStoriesActive from "../../../assets/images/igStories-active.svg";
import fbActive from "../../../assets/images/fb-active.svg";
import fbStoriesActive from "../../../assets/images/fbStories-active.svg";
import fbMessengerActive from "../../../assets/images/fbMessenger-active.svg";

interface BusinessesPageProps extends RouteComponentProps {}

const BusinessesPage: React.FC<BusinessesPageProps> = (props:any) => {

  const { history } = props;

  return (
    <>
      <Nav className="navbar navbar-expand-lg navbar-light sticky-top">
        <img
          src={logo}
          alt="GiveBee - Get the people buzzin'!"
          className="logo navbar-brand"
          onClick={() => {
            history.push("/");
          }}
        />
        <Burger history={ history } />
      </Nav>
      <Row className="row-lightYellow row-top">
        <Col xs={12} md={6} className="column-regular businessSignUpFormCol my-auto">
          <h1 className="businessesH1">Let your best customers promote you through the ups and downs.</h1>
          <h5 className="businessesH5 mt-2">Increase social posts tagging your business and easily reward customers with loyalty points.</h5>
          <BusinessesInquiryForm />
        </Col>
        <Col xs={12} md={4} className="centerContentX my-auto">
        </Col>
      </Row>
      <Row className="centerContentX row-about">
        <Col className="theCallCol">
          <h2 className="businessesH2">GiveBee makes it easy to<br />reward customers who promote you online.</h2>
          <br />
          <Row className="centerContentX mt-3">
            <Col xs={12} md={4}>
              <h5 className="businessesH5 px-3">Turn loyal customers into lifelong advocates.</h5>
            </Col>
            <Col xs={12} md={4}>
              <h5 className="businessesH5 px-3">Increase customer shares on:</h5>
              <div className="mt-1">
                <img className="social-icon" src={ igActive } alt="" />
                <img className="social-icon ml-1" src={ igStoriesActive } alt="" />
                <img className="social-icon ml-1" src={ fbActive } alt="" />
                <img className="social-icon ml-1" src={ fbStoriesActive } alt="" />
                <img className="social-icon ml-1" src={ fbMessengerActive } alt="" />
                <p>... and more to come!</p>
              </div>
            </Col>
            <Col xs={12} md={4}>
              <h5 className="businessesH5 px-3">Collect content for your social media pages directly from customers.</h5>
            </Col>
          </Row>
        </Col>
        <hr className="hr-homePage"/>
      </Row>
      <Row>
        <Col xs={12} md={6} className="contactUsCol">
          <h2 className="businessesH2">Contact us and let us increase the buzz about your business.</h2>
        </Col>
        <Col xs={12} md={6} className="businessBottomSignUpCol">
          <BusinessesInquiryForm />
        </Col>
      </Row>
    </>
  );
}

export default BusinessesPage;