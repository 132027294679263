// FC = Firestore Collection
export const FC = {
  businessBalances: 'businessBalances',
  busienssContacts: 'businessContacts',
  businessRewards: 'businessRewards',
  businessVisitors: 'businessVisitors',
  businesses: 'businesses',
  googlePlaces: 'googlePlaces',
  locations: 'locations',
  photoMetrics: 'photoMetrics',
  photoViewers: 'photoViewers',
  photos: 'photos',
  plaidItems: 'plaidItems',
  plaidItemsDeleted: 'plaidItemsDeleted',
  sponsorshipCodes: 'sponsorshipCodes',
  sponsorships: 'sponsorships',
  socialShares: 'socialShares',
  stripeCustomers: 'stripeCustomers',
  userNotifications: 'userNotifications',
  userPaymentRequests: 'userPaymentRequests',
  users: 'users',
  websiteVisitors: 'websiteVisitors',
}

// FF = Firestore Field
export const FF = {
  adBalance: 'adBalance',
  businessId: 'businessId',
  businessName: 'businessName',
  businessShareRequests: 'businessShareRequests',
  businessRequestTimestamps: 'businessRequestTimestamps',
  business_rewarded_photo: 'business_rewarded_photo',
  business_viewed_photo: 'business_viewed_photo',
  created_at: 'created_at',
  creatorSharedTo: 'creatorSharedTo',
  dishes: 'dishes',
  firstName: 'firstName',
  first_photo_view: 'first_photo_view',
  first_share_attempt: 'first_share_attempt',
  first_website_visit: 'first_website_visit',
  friendsIds: 'friendsIds',
  geohashArray: 'geohashArray',
  googlePlaceId: 'googlePlaceId',
  imageUrl: 'imageUrl',
  lastName: 'lastName',
  latest_business_share_request: 'latest_business_share_request',
  latest_metric: 'latest_metric',
  latest_post: 'latest_post',
  latest_photo_view: 'latest_photo_view',
  latest_points_increment: 'latest_points_increment',
  latest_share_attempt: 'latest_share_attempt',
  latest_website_visit: 'latest_website_visit',
  message: 'message',
  names: 'names',
  namesFirstName: 'names.firstName',
  photoId: 'photoId',
  photoUrl: 'photoUrl',
  platformId: 'platformId',
  posterUserId: 'posterUserId',
  potentialClientPointsAmount: 'potentialClientPointsAmount',
  potentialPointsAmount: 'potentialPointsAmount',
  requested_at: 'requested_at',
  sharedAttemptTimestamps: 'sharedAttemptTimestamps',
  shareAttempts: 'shareAttempts',
  sponsoredPost: 'sponsoredPost',
  status: 'status',
  title: 'title',
  totalPhotoViews: 'totalPhotoViews',
  totalWebsiteVisits: 'totalWebsiteVisits',
  type: 'type',
  uniquePhotoViews: 'uniquePhotoViews',
  uniquePhotoViewRate: 'uniquePhotoViewRate',
  uniqueWebsiteVists: 'uniqueWebsiteVists',
  url: 'url',
  userId: 'userId',
  userNotifications: 'userNotifications',
  username: 'username',
  user_viewed_at: 'user_viewed_at',
  viewerUserId: 'viewerUserId',
  viewTimestamps: 'viewTimestamps',
  visitTimestamps: 'visitTimestamps',
  visitorUserId: 'visitorUserId',
}

export const FFCharges = {
  currentPointsBalance: 'currentPointsBalance',
  totalCharge: 'totalCharge',
  totalPayout: 'totalPayout',
  totalPointsGenerated: 'totalPointsGenerated',

  totalPostViewCharge: 'totalPostViewCharge',
  totalPostViewPayout: 'totalPostViewPayout',
  totalPostViewPoints: 'totalPostViewPoints',

  totalWebsiteVisitCharge: 'totalWebsiteVisitCharge',
  totalWebsiteVisitPayout: 'totalWebsiteVisitPayout',
  totalWebsiteVisitPoints: 'totalWebsiteVisitPoints',

  totalSocialShareCharge: 'totalSocialShareCharge',
  totalSocialSharePayout: 'totalSocialSharePayout',
  totalSocialSharePoints: 'totalSocialSharePoints',

  photosRewarded: 'photosRewarded',
  photoRewardCharge: 'photoRewardCharge',
  photoRewardPayout: 'photoRewardPayout',
  photoRewardPoints: 'photoRewardPoints',
}

// Platform ids
export const PlatformIds = {
  ig: 'ig',
  igStories: 'igStories',
  fb: 'fb',
  fbStories: 'fbStories',
  fbMessenger: 'fbMessenger',
}

// Notification Types
export const NotifTypes = {
  socialShareRequest: 'socialShareRequest',
}