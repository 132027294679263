// React Components and Other Libraries
import React, { useState, useEffect } from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import { convertGiveBeeIntToUSDDecimal } from "../../helpers/PointsAndMoneyConversions";

// UI CSS
import coinIcon from "../../assets/images/coin-icon.svg";
import xIcon from "../../assets/images/X-icon-dark.svg";

const ContestRewardsModal: React.FC<any> = (props:any) => {

  const {
    rewardsModalOpen,
    setRewardsModal,
  } = props;

  useEffect(() => {
    setModal();
  }, []);

  function setModal() {
    if (rewardsModalOpen) {
      const element = document.getElementById("rewardOverlay");
      if (element !== null) {
        element.style.display = "block";
      }
    }
  }

  return (
    <Row className="rewardContentModal">
      <Row>
        <Col className="text-right">
          <button
            className="button-icon mr-3"
            onClick={() => setRewardsModal(false) }
          >
            <img className="regularIcon ml-3 mt-3" alt="exit" src={ xIcon } />
          </button>
        </Col>
      </Row>
      <Col>
        <Row>
          <Col>
            <h4 className="mt-2">Giveaways</h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <ul>
              <li className="text-left">
                $20 gift card
              </li>
              <li className="text-left">
                Number of winners: 2
              </li>
            </ul>
          </Col>
        </Row>
      </Col>
    </Row>
  );

}

export default ContestRewardsModal;