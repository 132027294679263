import React, { Component } from "react";
import { RouteComponentProps } from 'react-router-dom';
import { firebaseAuth, db } from "../../firebase";
import { loginWithEmailAndPassword, getUserTypeInfo, logout } from "../../helpers/auth";
import * as pathNames from "../../constants/PathNames";

import "./BusinessSignIn.css";
import logo from "../../assets/images/GiveBee_201.png";

const appTokenKey = "appToken";

const INITIAL_STATE = {
  email: "",
  password: "",
  error: null
};

interface BusinessSignInProps extends RouteComponentProps {}

interface BusinessSignInState {
  email: string;
  password: string;
  error: any;
}

class BusinessSignIn extends Component<
  BusinessSignInProps,
  BusinessSignInState
> {
  constructor(props: any) {
    super(props);

    firebaseAuth().onAuthStateChanged(user => {
      if (user) {
        localStorage.setItem(appTokenKey, user.uid);

        getUserTypeInfo(user.uid)
          .then((userType) => {
            if (userType === "existingBusinessUser") {
              db.collection("businesses")
                .doc(user.uid)
                .get()
                .then(businessDoc => {
                  if (businessDoc.exists) {
                    let userData = businessDoc.data();

                    if (userData === undefined) { return; }
                  }
                })
                .then(() => {
                  this.props.history.push(pathNames.businessDashboardPath);                  
                });
            } else {
              // We only want one auth session at a time
              // We assume if someone comes to this page, they are not a poster user
              // They can re-sign in later if they scan to post
              logout()
            }
          })
          .catch((error) => {
            console.log("Error ocurred in checking current user");
            console.error(error);
          })
      }
    });

    this.state = { ...INITIAL_STATE };
    this.onChange = this.onChange.bind(this);
  }

  onSubmit = (event: any) => {
    const { email, password } = this.state;

    loginWithEmailAndPassword(email, password)
      .then(resp => {
        this.props.history.push(pathNames.businessCustomersPath);
      })
      .catch(error => {
        console.error(error);
        this.setState({ error });
        // clear state
        this.setState({ password: "" });
      });
    event.preventDefault();
  };

  onChange = (event: { target: { name: any; value: any } }) => {
    if (event != null) {
      const newState = { [event.target.name]: event.target.value } as Pick<
        BusinessSignInState,
        keyof BusinessSignInState
      >;
      this.setState(newState);
    }
  };

  render() {
    const { email, password, error } = this.state;

    const isInvalid = password.length < 6 || email === "";

    return (
      <div className="businessSignIn">
        <img
          src={logo}
          alt="GiveBee - Get the people buzzin'!"
          className="logoNorm"
          onClick={() => {
            this.props.history.push("/")
          }}
        />
        <div className="mt-5">
          <h3 className="mainText">
            Welcome Back!
          </h3>
          <p className="subText">
            We missed you while you were gone.
          </p>
          <br />
          <div className="signUpForm">
            <form onSubmit={this.onSubmit}>
              <input
                className="form-field form-field-auth"
                name="email"
                value={email}
                onChange={this.onChange}
                type="email"
                placeholder="Email"
              />
              <br />
              <input
                className="form-field form-field-auth"
                name="password"
                value={password}
                onChange={this.onChange}
                type="password"
                placeholder="Password"
              />
              <br />
              <button className="logInButton" disabled={isInvalid} type="submit">
                Log In
              </button>
              <br />
              <p>No account yet?      <a id="followerFreeBeeLink" className="standardLink" href={"/business/signup"}>
                  Sign Up
                </a>
              </p>
              {/* <a id="followerFreeBeeLink" className="standardLink" href={"www.google.com"}>
                Forgot Password?
              </a> */}
              {error && <p>{error.message}</p>}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default BusinessSignIn;
