/**
 * Mostly a subclass object used for business and locationobjects
 */
export class Address {
  public address1:string;
  public address2:string;
  public city:string;
  public state: string;
  public zipCode:string;

  constructor (data:any) {
    this.address1 = (data.address1 ? data.address1 : "");
    this.address2 = (data.address2 ? data.address2: "");
    this.city = (data.city ? data.city : "");
    this.state = (data.state ? data.state : "");
    this.zipCode = (data.zipCode ? data.zipCode : (data.postalCode ? data.postalCode : ""));
  }
}

/**
 * Class object for business user
 */
export class Business {
  public id:string;
  public accountStatuses:{[key:string]: any} = {};
  public address:Address;
  public adminFullName:string;
  public badgeImageUrl:string;
  public businessId:string;
  public businessName:string;
  public openingHoursPeriods:{[key:string]: any} = {};
  public phone:string;
  public website:string;

  constructor (id:string, data:any) {
    this.id = id
    this.accountStatuses = (data.accountStatuses ? data.accountStatuses : {});

    const addressData = (data.address ? data.address : data);
    this.address = new Address(addressData);

    this.adminFullName = (data.adminFullName ? data.adminFullName : (data.fullName ? data.fullName : ""));
    this.badgeImageUrl = data.badgeImageUrl ? data.badgeImageUrl : "";
    this.businessId = data.businessId;
    this.businessName = (data.businessName ? data.businessName : "");
    
    this.openingHoursPeriods = data.openingHoursPeriods 
    this.phone = data.phone;
    this.website = data.website;
  }
}

/**
 * Class object that represents a photo posted by a user
 */
export class Photo {
  public address:string;
  public businessName: string;
  public businessId:string;          // present in GiveBee Non-Google photos
  public createdAt: string;
  public googlePlaceId:string;       // present in GiveBee Google photos
  public locationId:string;          // present in GiveBee Non-Google photos
  public openingHours:Map<string,any>;
  public phoneNumber:string;
  public sponsored:boolean;
  public url:string;
  public userInfo:any;
  public website:string;

  constructor (data:any) {
    this.address = (data.address ? data.address : '');
    this.businessName = (data.businessName ? data.businessName : '');
    this.businessId = data.businessId;
    this.createdAt = data.created_at;
    this.locationId = data.locationId;
    this.googlePlaceId = data.googlePlaceId;
    this.openingHours = data.openingHours;  // will be undefined for GiveBee photos
    this.phoneNumber = (data.phoneNumber ? data.phoneNumber : '');
    this.sponsored = (data.sponsored ? data.sponsored : false);
    this.url = (data.url ? data.url : '');
    this.userInfo = data.userInfo;  // will be undefined for Google photos
    this.website = (data.website ? data.website : '');
  }
};


/**
 * Small class for rewards that represent an array field "rewards" within businessRewards
 */
export class Reward {
  public reward:string;
  public amount:number;

  constructor (data:any) {
    this.reward = data.reward;
    this.amount = data.amount;
  }
}

/**
 * Class object that represents a sponsorship card data but is also someone who posted a photo for a business
 */
export class BusinessVisitor {    
  public id:string;
  public currentPointsBalance:number;
  public firstName:string;
  public totalTransactionMatchAmount:number;
  public latest_transaction:number;
  public latest_post:number;
  public lastName:string;
  public photos:number;
  public status:string;
  public username:string;

  constructor (id:string,data:any) {
    
    this.id = id;
    this.currentPointsBalance = (data.currentPointsBalance) ? data.currentPointsBalance : 0;
    this.firstName = (data.names)
                    ? ((data.names.firstName) ? data.names.firstName : "")
                    : ((data.name) ? data.name.firstName : "");
    this.photos = (data.photos) ? data.photos : 0;
    this.latest_transaction = (data.latest_transaction) ? data.latest_transaction : 0;
    this.latest_post = (data.latest_post) ? data.latest_post : (data.last_post ? data.last_post : 0);
    this.lastName = (data.names)
                    ? ((data.names.lastName) ? data.names.lastName : "")
                    : ((data.name) ? data.name.lastName : "");
    this.photos = (data.photos) ? data.photos : 0;
    this.status = (data.status) ? data.status : "none";
    this.totalTransactionMatchAmount = (data.totalTransactionMatchAmount) ? data.totalTransactionMatchAmount : 0;
    this.username = (data.names)
                    ? ((data.names.username) ? data.names.username : "")
                    : ((data.name) ? data.name.username : "");
  }
}